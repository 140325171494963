import educationImage from "../../assets/images/headline_images/new_education_image.jpg"
import christophGollImage from "../../assets/images/christoph_goll_new.jpeg"
import { Link } from "react-router-dom"

export default function Education() {
    return <div className="pb-8 cursor-default">
        <div className="relative pb-1">
            <div className="flex flex-row justify-center">
                <img src={educationImage} alt="Education Titelfoto" className="w-[95%] rounded-sm" />
            </div>

            <div className="absolute bottom-[50%] left-[50%] translate-x-[-50%] translate-y-[50%] z-10">
                <p className="text-2xl sm:text-4xl font-semibold text-white tracking-wide">EDUCATION</p>
            </div>
        </div>

        <div className="pl-[7%] flex justify-start">
            <div className="w-[90%] sm:w-[60%]">
                <p className="text-xl font-semibold pb-3 pt-3">
                    ÜBER EDUCATION
                </p>
                <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                    labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
                    et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                    labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
                    et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                </p>
                <p className="pt-4">
                    Finden Sie jetzt Ihr passendes Seminar unter&nbsp;<Link
                        to="/seminare" className="font-bold cursor-pointer">SEMINARE</Link>
                </p>
            </div>

            <div className="pl-[15%] pt-10 hidden sm:block">
                <img src={christophGollImage} alt="Christoph Goll" className="w-40 h-40 rounded-full overflow-hidden object-cover" />
            </div>
        </div>
    </div>
}