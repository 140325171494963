import haircutImage from "../../assets/images/headline_images/new_haircut_image.jpg"
import CustomCarousel from "../../components/carousel/HairstyleCarousel"
import HorizontalSeparator from "../../components/layout/HorizontalSeparator"

export default function PriceList() {

    return <>
        <div className="relative pb-4 cursor-default">
            <div className="flex justify-center">
                <img src={haircutImage} alt="Haarschnitt Männer" className="w-[95%] rounded-sm" />
            </div>

            <div className="absolute bottom-[40%] left-[50%] translate-x-[-50%] z-10">
                <p className="text-3xl font-semibold text-white">PREISLISTE</p>
            </div>
        </div>

        <div className="flex flex-wrap justify-between">
            <div className="pl-[20%]">
                <div className="pb-3">
                    <p className="font-semibold text-lg">
                        Schneiden
                    </p>
                    <p>
                        € 46,- bis € 79,-
                    </p>
                </div>
                <div className="pb-3">
                    <p className="font-semibold text-lg">
                        Bartformung
                    </p>
                    <p>
                        € 12,-
                    </p>
                </div>
                <div className="pb-3">
                    <p className="font-semibold text-lg">
                        Haare schön machen (föhnen / stylen)
                    </p>
                    <p>
                        € 41,- bis € 54,-
                    </p>
                </div>
                <div className="pb-3">
                    <p className="font-semibold text-lg">
                        Locken und Glätten (ohne waschen)
                    </p>
                    <p>
                        € 43,-
                    </p>
                </div>
                <div className="pb-3">
                    <p className="font-semibold text-lg">
                        Farbveränderung
                    </p>
                    <p>
                        € 62,- bis € 102,-
                    </p>
                </div>
                <div className="pb-3">
                    <p className="font-semibold text-lg">
                        Airtouch Technik (Blond Sepcial)
                    </p>
                    <p>
                        Preis auf Anfrage
                    </p>
                </div>
                <div className="pb-3">
                    <p className="font-semibold text-lg">
                        Farbauffrischung Smoothie
                    </p>
                    <p>
                        Pro Gramm = € 1,20
                    </p>
                </div>
            </div>

            <div className="pr-[20%] pl-[20%]">
                <div className="pb-3">
                    <p className="font-semibold text-lg">
                        Farbauffrischung Gloss
                    </p>
                    <p>
                        Pro Gramm = € 1,50
                    </p>
                </div>
                <div className="pb-3">
                    <p className="font-semibold text-lg">
                        Strähnen
                    </p>
                    <p>
                        € 56,- bis € 150,-
                    </p>
                </div>
                <div className="pb-3">
                    <p className="font-semibold text-lg">
                        Einzelsträhnen
                    </p>
                    <p>
                        Pro Packung € 3,10
                    </p>
                </div>
                <div className="pb-3">
                    <p className="font-semibold text-lg">
                        Umformungen
                    </p>
                    <p>
                        € 65,- bis € 95,-
                    </p>
                </div>
                <div className="pb-3">
                    <p className="font-semibold text-lg">
                        Augenbrauen und Wimpern
                    </p>
                    <p>
                        € 15,-
                    </p>
                </div>
                <div className="pb-3">
                    <p className="font-semibold text-lg">
                        Pflegebehandlungen
                    </p>
                    <p>
                        € 5,- bis € 20,-
                    </p>
                </div>
            </div>
        </div>

        <div className="flex h-96 justify-center pl-[30%] pr-[30%] pt-10 ">
            <CustomCarousel />
        </div>

        <div className="pt-10">
            <HorizontalSeparator />
        </div>
    </>
}