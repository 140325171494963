import { ReactNode } from "react"
import Header from "./Header"
import Footer from "./Footer"

export default function Layout({ children }: { children: ReactNode }) {
    return <div className="bg-black text-white">
        <Header />
        <div className="pt-24 sm:pt-44">
            {children}
        </div>
        <Footer />
    </div>
}