import { Route } from "react-router-dom"
import Home from "../pages/home/Home"
import Imprint from "../pages/imprint/Imprint"
import DataProtection from "../pages/dataprotection/DataProtection"
import PriceList from "../pages/pricelist/PriceList"
import Contact from "../components/contact/Contact"
import Seminar from "../pages/seminar/Seminar"
import AboutMe from "../pages/about/AboutMePage"
import Products from "../pages/products/Products"

export default function PageRoutes() {
    return <>
        <Route path="/" element={<Home />} />
        <Route path="/impressum" element={<Imprint />} />
        <Route path="/datenschutz" element={<DataProtection />} />
        <Route path="/preisliste" element={<PriceList />} />
        <Route path="/kontakt" element={<Contact />} />
        <Route path="/seminare" element={<Seminar />} />
        <Route path="/ueber" element={<AboutMe />} />
        <Route path="/produkte" element={<Products />} />
    </>
}