import React, { useEffect, useState } from "react"
import logo from "../../../assets/images/logo.jpg"
import DropDown from "./Dropdown"
import { Link, useLocation } from "react-router-dom"

const Menu: React.FC = () => {
    const [showDropDown, setShowDropDown] = useState<boolean>(false)
    const location = useLocation()

    const toggleDropDown = () => {
        setShowDropDown(!showDropDown)
    }

    const dismissHandler = (event: React.FocusEvent<HTMLButtonElement>): void => {
        if (event.currentTarget === event.target && event.target.id.match('dropdown')) {
            setShowDropDown(false)
        }
    }

    useEffect(() => {
        if (location.pathname === "/" && location.state?.from !== "header") {
            document.documentElement.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            })
        }

    }, [location])

    return <>
        <div className="flex justify-between">
            <Link to="/">
                <img src={logo} className="h-16 pointer-events-none pl-[10%]" alt="Logo" draggable="false" />
            </Link>

            <button
                className={showDropDown ? "active" : undefined}
                onClick={(): void => toggleDropDown()}
                onBlur={(e: React.FocusEvent<HTMLButtonElement>): void =>
                    dismissHandler(e)
                }>

                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-10 h-10  mr-[5%] mt-1">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                    </svg>
                </div>

                {showDropDown && (
                    <DropDown
                        showDropDown={false}
                        toggleDropDown={(): void => toggleDropDown()}
                    />
                )}
            </button>

        </div>

        <hr className="w-full border-t-2 border-white mt-4" />
    </>
}

export default Menu
