import logo from "../../assets/images/logo.jpg"
import { useState, useEffect } from "react"

export default function Contact() {
    const [isEnoughSpace, setIsEnoughSpace] = useState<boolean>(true)

    useEffect(() => {
        const handleResize = () => {
            setIsEnoughSpace(window.innerWidth > 700)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return <>
        <div className="flex flex-row justify-center items-center pb-6" id="contactInformation">
            <img src={logo} className={isEnoughSpace ? "h-28" : "h-16"} draggable="false" alt="Logo" />
        </div>

        <div className="flex justify-between flex-wrap pb-6">
            <div className="pl-[20%] pb-6">
                <p className="font-semibold text-xl">
                    Öffnungszeiten:
                </p>
                <p>
                    Termine auf Anfrage
                </p>
            </div>

            <div className="pr-[20%] pl-[20%]">
                <p className="font-semibold text-xl">
                    Goll und Moosbauer OG / <br /> Christoph Goll fine hair
                </p>


                <div className="pt-1 flex flex-row">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5 mr-2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                    </svg>
                    <a href="https://maps.app.goo.gl/21EJdqUJ39pK2W4E6">
                        Ruflinger Strasse 17 <br />
                        4060 Leonding
                    </a>
                </div>

                <div className="pt-1 flex flex-row">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" className="w-5 h-5 mr-2 mt-1">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                    </svg>

                    <a href="tel:+436603143190">
                        +43 660 3143190
                    </a>
                </div>

                <div className="pt-1 flex flex-row">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" className="w-5 h-5 mr-2 mt-1">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                    </svg>

                    <a href="mailto:office@christoph-goll.at" className="underline text-blue-500 hover:text-blue-700">
                        office@christoph-goll.at
                    </a>
                </div>
            </div>
        </div>

        <div className="flex justify-center">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d663.8082160669783!2d14.247214451767736!3d48.27913575875009!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4773971c6cd6f425%3A0xe89fa4834f2440af!2sRuflinger%20Str.%2017%2C%204060%20Leonding!5e0!3m2!1sde!2sat!4v1707306577870!5m2!1sde!2sat"
                width="800" height="600" loading="lazy" referrerPolicy="no-referrer-when-downgrade"
                title="Google Maps Location"
                className={isEnoughSpace ? "w-[95%] h-96 rounded-sm" : "w-[80%] h-60 rounded-sm"} />
        </div>
    </>
}