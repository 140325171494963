import HorizontalSeparator from "../../components/layout/HorizontalSeparator"

export default function DataProtection() {
    return <>
        <div className="pt-8 pl-[6%] w-[90%]">
            <p className="text-3xl font-semibold tracking-wide pb-5">
                Datenschutz
            </p>

            <p className="pb-5 text-2xl font-semibold">
                Datenschtutz auf einen Blick
            </p>

            <div className="pb-5">
                <p className="text-lg underline font-semibold">
                    Allgemeine Hinweise
                </p>

                <p className="text-sm">
                    Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten
                    passiert,
                    wenn Sie unsere Website besuchen. Personenbezogene Daten sind <br /> alle Daten, mit denen Sie persönlich
                    identifiziert
                    werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text
                    aufgeführten
                    Datenschutzerklärung.
                </p>
            </div>

            <div className="pb-5">
                <p className="text-lg underline font-semibold">
                    Datenerfassung auf unserer Website
                </p>

                <div className="text-sm pb-3">
                    <p className="font-semibold">
                        Wer ist verantwortlich für die Datenerfassung auf dieser Website?
                    </p>

                    <p>
                        Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber.
                        Dessen Kontaktdaten können Sie dem Impressum dieser Website entnehmen.
                    </p>
                </div>

                <div className="text-sm pb-3">
                    <p className="font-semibold">
                        Wie erfassen wir Ihre Daten?
                    </p>

                    <p>
                        Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen.
                        Hierbei kann es sich z.B. um Daten handeln, die Sie in ein Kontaktformular eingeben.
                    </p>

                    <br />

                    <p>
                        Andere Daten werden automatisch beim Besuch der Website durch unsere IT-Systeme erfasst.
                        Das sind vor allem technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des
                        Seitenaufrufs). <br /> Die Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere Website
                        betreten.
                    </p>
                </div>

                <div className="text-sm pb-3">
                    <p className="font-semibold">
                        Wofür nutzen wir Ihre Daten?
                    </p>

                    <p>
                        Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten.
                        Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.
                    </p>
                </div>

                <div className="text-sm">
                    <p className="font-semibold">
                        Welche Rechte haben Sie bezüglich Ihrer Daten?
                    </p>

                    <p>
                        Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer
                        gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung,
                        <br /> Sperrung oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema
                        Datenschutz können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
                        <br /> Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
                    </p>
                </div>
            </div>

            <p className="pb-5 text-2xl font-semibold">
                Allgemeine Hinweise und Pflichtinformationen
            </p>

            <div className="pb-5">
                <p className="text-lg underline font-semibold">
                    Datenschutz
                </p>

                <p className="text-sm">
                    Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst.
                    Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen
                    Datenschutzvorschriften <br /> sowie dieser Datenschutzerklärung.

                    Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben.
                    Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert <br /> werden können.
                    Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen.
                    Sie erläutert auch, wie und zu welchem Zweck das geschieht.

                    Wir weisen darauf hin, <br /> dass die Datenübertragung im Internet (z.B. bei der Kommunikation per
                    E-Mail)
                    Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch
                    Dritte ist nicht möglich.
                </p>
            </div>

            <div className="pb-5">
                <p className="text-lg underline font-semibold">
                    Hinweis zur verantwortlichen Stelle
                </p>

                <div className="text-sm pb-3">
                    <p className="font-semibold">
                        Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:
                    </p>

                    <p>
                        Goll und Moosbauer OG /
                        Christoph Goll fine hair
                        <br />
                        Ruflinger Straße 17
                        <br />
                        4060 Leonding
                        <br />
                        <a href="tel:+436603143190">
                            +43 660 3143190
                        </a>
                        <br />
                        <a href="https://https://www.christoph-goll.at" target="_blank" className="underline text-blue-500 hover:text-blue-700"
                            rel="noreferrer noopener">
                            www.schneiderei-friseure.at
                        </a>
                        <br />
                        <a href="mailto:office@christoph-goll.at" className="underline text-blue-500 hover:text-blue-700">
                            office@christoph-goll.at
                        </a>
                        <br />
                        <br />
                        Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder
                        gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen
                        Daten <br /> (z.B. Namen, E-Mail-Adressen o. Ä.) entscheidet.
                    </p>
                </div>

                <div className="text-sm pb-3">
                    <p className="font-semibold">
                        Widerruf Ihrer Einwilligung zur Datenverarbeitung
                    </p>

                    <p>
                        Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich.
                        Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose
                        Mitteilung <br /> per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
                        Datenverarbeitung
                        bleibt vom Widerruf unberührt.
                    </p>
                </div>

                <div className="text-sm pb-3">
                    <p className="font-semibold">
                        Beschwerderecht bei der zuständigen Aufsichtsbehörde
                    </p>

                    <p>
                        Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein Beschwerderecht bei der
                        zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen
                        ist der <br /> Landesdatenschutzbeauftragte des Bundeslandes, in dem unser Unternehmen seinen Sitz
                        hat.
                        Eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten können folgendem Link
                        entnommen werden: <br />

                        <a href="https://www.bfdi.bund.de/DE/Service/Anschriften/anschriften_table.html" target="_blank"
                            className="underline text-blue-500 hover:text-blue-700" rel="noreferrer noopener">
                            BfDI - Anschriften und Links
                        </a>
                    </p>
                </div>

                <div className="text-sm pb-3">
                    <p className="font-semibold">
                        SSL- bzw. TLS-Verschlüsselung
                    </p>

                    <p>
                        Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte,
                        wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, <br />
                        eine SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die
                        Adresszeile des Browsers von “http://” auf “https://” wechselt und an dem Schloss-Symbol in
                        Ihrer Browserzeile. <br />

                        Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln,
                        nicht von Dritten mitgelesen werden.
                    </p>
                </div>

                <div className="text-sm pb-3">
                    <p className="font-semibold">
                        Auskunft, Sperrung, Löschung
                    </p>

                    <p>
                        Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche
                        Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den
                        <br /> Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung
                        dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich
                        jederzeit unter <br /> der im Impressum angegebenen Adresse an uns wenden.
                    </p>
                </div>

                <div className="text-sm">
                    <p className="font-semibold">
                        Widerspruch gegen Werbe-Mails
                    </p>

                    <p>
                        Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung
                        von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit
                        widersprochen. <br /> Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im
                        Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
                    </p>
                </div>
            </div>

            <p className="pb-5 text-2xl font-semibold">
                Datenerfassung auf unserer Website
            </p>

            <div className="pb-5">
                <p className="text-lg underline font-semibold">
                    Cookies
                </p>

                <p className="text-sm">
                    Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner
                    keinen Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher,
                    <br /> effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt
                    werden und die Ihr Browser speichert. Die meisten der von uns verwendeten Cookies sind so genannte
                    <br /> “Session-Cookies”. Sie werden nach Ende Ihres Besuchs automatisch gelöscht. Andere Cookies bleiben
                    auf Ihrem Endgerät gespeichert bis Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser
                    <br /> beim nächsten Besuch wiederzuerkennen.

                    <br />
                    <br />
                    Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden
                    und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell
                    ausschließen <br /> sowie das automatische Löschen der Cookies beim Schließen des Browser aktivieren.
                    Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.

                    Cookies, die zur Durchführung <br /> des elektronischen Kommunikationsvorgangs oder zur Bereitstellung
                    bestimmter, von Ihnen erwünschter Funktionen (z.B. Warenkorbfunktion) erforderlich sind, werden
                    auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO <br /> gespeichert. Der Websitebetreiber hat ein
                    berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und optimierten
                    Bereitstellung seiner Dienste. Soweit andere Cookies (z.B. Cookies zur <br /> Analyse Ihres
                    Surfverhaltens) gespeichert werden, werden diese in dieser Datenschutzerklärung gesondert behandelt.
                </p>
            </div>

            <div className="pb-5">
                <p className="text-lg underline font-semibold">
                    Server-Log-Dateien
                </p>

                <div className="text-sm">
                    <p>
                        Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten
                        Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. <br /> Dies sind:
                    </p>

                    <br />

                    <ul className="list-disc pl-8">
                        <li>Browsertyp und Browserversion</li>
                        <li>verwendetes Betriebssystem</li>
                        <li>Referrer URL</li>
                        <li>Hostname des zugreifenden Rechners</li>
                        <li>Uhrzeit der Serveranfrage</li>
                        <li>IP-Adresse</li>
                    </ul>

                    <br />

                    <p>
                        Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. <br />

                        Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von
                        Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
                    </p>
                </div>
            </div>

            <div className="pb-5">
                <p className="text-lg underline font-semibold">
                    Kontaktformular
                </p>

                <p className="text-sm">
                    Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem
                    Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der
                    Anfrage und für <br /> den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht
                    ohne Ihre Einwilligung weiter. <br />

                    <br />

                    Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt somit ausschließlich auf
                    Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung jederzeit
                    widerrufen. <br /> Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum
                    Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt. <br />

                    Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung
                    auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung
                    entfällt <br /> (z.B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen
                    – insbesondere Aufbewahrungsfristen – bleiben unberührt.
                </p>
            </div>

            <div className="pb-5">
                <p className="text-lg underline font-semibold">
                    Verarbeiten von Daten (Kunden- und Vertragsdaten)
                </p>

                <p className="text-sm">
                    Wir erheben, verarbeiten und nutzen personenbezogene Daten nur, soweit sie für die Begründung,
                    inhaltliche Ausgestaltung oder Änderung des Rechtsverhältnisses erforderlich sind (Bestandsdaten).
                    Dies erfolgt <br /> auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur
                    Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet. Personenbezogene Daten über
                    die Inanspruchnahme unserer <br /> Internetseiten (Nutzungsdaten) erheben, verarbeiten und nutzen wir
                    nur, soweit dies erforderlich ist, um dem Nutzer die Inanspruchnahme des Dienstes zu ermöglichen oder
                    abzurechnen. <br />
                    <br />
                    Die erhobenen Kundendaten werden nach Abschluss des Auftrags oder Beendigung der Geschäftsbeziehung
                    gelöscht. Gesetzliche Aufbewahrungsfristen bleiben unberührt.
                </p>
            </div>

            <p className="pb-5 text-2xl font-semibold">
                Plugins und Tools
            </p>

            <div className="pb-5">
                <p className="text-lg underline font-semibold">
                    Google Maps
                </p>

                <p className="text-sm">
                    Diese Seite nutzt über eine API den Kartendienst Google Maps. Anbieter ist die Google Inc.,
                    1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. <br />

                    Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP Adresse zu speichern.
                    Diese Informationen werden in der Regel an einen Server von Google in den USA übertragen und
                    dort gespeichert. <br /> Der Anbieter dieser Seite hat keinen Einfluss auf diese Datenübertragung. <br />

                    Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden Darstellung unserer
                    Online-Angebote und an einer leichten Auffindbarkeit der von uns auf der Website angegebenen Orte.
                    Dies stellt ein <br /> berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. <br />

                    Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von Google: &nbsp;
                    <a href="https://www.google.de/intl/de/policies/privacy/" target="_blank" className="underline"
                        rel="noreferrer noopener">
                        https://www.google.de/intl/de/policies/privacy/
                    </a>
                    .
                </p>
            </div>
        </div>

        <HorizontalSeparator />
    </>
}