import { Link } from "react-router-dom"

export default function NotFoundPage() {
    return <>
        <p className="text-3xl font-semibold tracking-wide text-center pb-5 text-red-500">
            Seite nicht gefunden
        </p>

        <p className="text-center pb-5">
            Die von Ihnen angegebene Seite wurde leider nicht gefunden. <br />
            Probieren Sie vielleicht die oben angeführten Links, <br />
            oder klicken Sie <Link to="/" className="text-blue-700 font-semibold">HIER</Link>, um zur Startseite zu
            gelangen.
        </p>
    </>
}