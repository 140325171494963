import logo from "../../assets/images/logo.jpg"
import React, { useCallback, useEffect, useState } from "react"
import { Link, useLocation, } from "react-router-dom"
import Menu from "./dropdown/Menu"

export default function Header() {
    const [isFromContact, setFromContact] = useState(false)
    const location = useLocation()
    const [prevScrollPos, setPrevScrollPos] = useState<number>(0)
    const [isLogoVisible, setLogoVisible] = useState(true)

    const handleScroll = useCallback(() => {
        const currentScrollPos = window.scrollY

        if (currentScrollPos > prevScrollPos) {
            // Scrolling down
            setLogoVisible(false)
        } else {
            // Scrolling up
            setLogoVisible(true)
        }

        setPrevScrollPos(currentScrollPos)
    }, [prevScrollPos])

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [prevScrollPos, handleScroll])

    useEffect(() => {
        if (location.pathname === "/" && location.state?.from !== "header") {
            if (isFromContact) {
                var my_element = document.getElementById("contactInformation")

                my_element?.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "nearest"
                })
            } else {
                document.documentElement.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth"
                })
            }
        } else {
            document.documentElement.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            })
        }
    }, [location, isFromContact])

    return <>
        <header className="sm:flex hidden pt-5 flex-col items-center justify-center fixed top-0 left-0 w-full z-50 bg-black text-white">
            {isLogoVisible ? <Link to="/" onClick={() => setFromContact(false)}>
                <img src={logo} className="h-16 sm:h-28 pointer-events-none" alt="Logo" draggable="false" />
            </Link> : <></>}

            <ul className="flex flex-wrap items-center justify-center list-none font-semibold text-lg tracking-wide decoration-2 underline-offset-4 ">
                <li className="me-4 md:me-10 cursor-pointer hover:underline">
                    <Link to="/produkte">PRODUKTE</Link>
                </li>
                <li className="me-4 md:me-10 cursor-pointer hover:underline">
                    <Link to="/preisliste">PREISLISTE</Link>
                </li>
                <li className="me-4 md:me-10 cursor-pointer hover:underline">
                    <Link to="/ueber">ÜBER MICH</Link>
                </li>
                <li className="me-4 md:me-10 cursor-pointer hover:underline">
                    <Link to="/" onClick={() => setFromContact(true)}>KONTAKT</Link>
                </li>
                <li className="me-4 md:me-10 cursor-pointer hover:underline">
                    <Link to="/seminare">SEMINARE</Link>
                </li>
            </ul>

            <hr className="w-full border-t-2 border-white mt-4" />

        </header>

        <header className="sm:hidden block pt-5 fixed top-0 left-0 w-full z-50 bg-black text-white">
            <Menu />
        </header>
    </>
}