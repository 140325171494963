interface Props {
    image: any
    title: string
    details: string
    duration: string
    description: string
    extra: string
    targetGroup: string
    price: string
}

const SeminarEntry: React.FC<Props> = ({ image, title, details, duration, description, extra, targetGroup, price }) => {
    return <div className="flex-wrap hover:bg-gray-800 hover:rounded-md p-2">
        <div className="block">
            <img src={image} alt="Christoph Goll" className="w-28 h-28 rounded-full overflow-hidden object-cover" />
        </div>

        <p className="font-semibold pt-3 text-lg">
            {title}
        </p>

        <p className="pt-3">
            {description}
        </p>

        <p className="pt-8 text-sm pl-[3%]">
            {details}
        </p>

        <p className="pt-3 text-sm pl-[3%]">
            Dauer: {duration}
        </p>

        {extra === "" ? <></> : <p className="pt-3 text-sm pl-[3%]">
            {extra}
        </p>}

        <p className="pt-3 text-sm pl-[3%]">
            {targetGroup}
        </p>

        <p className="pt-3 text-sm pl-[3%]">
            {price}
        </p>
    </div>
}

export default SeminarEntry