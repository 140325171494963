import christophGollImage from "../../assets/images/christoph_goll_new.jpeg"
import storeVideo from "../../assets/videos/store_showcase.mp4"
import HorizontalSeparator from "../layout/HorizontalSeparator"

export default function AboutMeComponent() {
    return <>
        <div className="pl-[7%] flex justify-start pb-8 flex-wrap pt-4 sm:pt-0">
            <div className="sm:pt-10 sm:pl-0 pl-[3%]">
                <img src={christophGollImage} alt="Christoph Goll" className="sm:w-48 sm:h-48 w-32 h-32 rounded-full overflow-hidden object-cover" />

                <div className="pt-20 pl-[5%] w-48 hidden sm:block">
                    <video autoPlay loop muted playsInline>
                        <source src={storeVideo} type="video/mp4" />
                    </video>
                </div>
            </div>



            <div className="w-[90%] pl-[6%] sm:w-[70%] sm:pl-[6%]">
                <p className="text-xl font-semibold pb-3 pt-6">
                    ÜBER MICH
                </p>

                <p className="pt-2">
                    Mein Name ist Christoph Goll.
                    <br /> <br />
                    Ich arbeite als Stylist seit 21 Jahren
                    Meine Stationen als Angestellter waren bei Salons wie Tony &amp; Guy , Sebastian und Vidal Sassoon
                    Seit 17 Jahren bin ich Selbständig.
                    <br /> <br />
                    Meinen ersten Salon eröffnete ich mit meinem Bruder ( Goll &amp; Goll ). Weitere Salons folgten wie die
                    Barschneiderei die als Friseur und Bar/Club ins Leben gerufen wurden. Danach konzentrierte ich
                    mich wieder mehr auf meine Friseur Tätigkeit und eröffnete die Schneiderei Friseure wo unter
                    anderem 7 Mitarbeiter bei uns tätig waren.
                    <br /> <br />
                    Vor 6 Jahren begann auch neben meiner selbständigkeit als Unternehmer die Reise als Education
                    Trainer bei der Firma Purehaircare. Ich durfte nach 3 Jahren die Leitung für Österreich übernehmen und
                    gleichzeitig als Internationaler Trainer bei Zone-concept starten. Internationale Einsätze für Bühnenshows
                    in Valencia und Ibiza waren hier meine ersten Erfahrungen ausserhalb des Salon Leben.
                    <br /> <br />
                    Durch diesen Fokus entschied ich mich meinen jetzigen Salon Christoph Goll fine hair zu realisieren. <br />
                    Hier arbeite ich alleine, für meine Kunden und kann gleichzeitig meine Entwicklung als Friseurtrainer
                    nach vorne bringen.
                    <br /> <br />
                    Meine Leidenschaft gilt schon immer dem modernen technischen entwickelten Haarschnitt. <br />
                    Mittlerweile liegt aber mein Fokus auch auf dem Färben. Speziell der Königsliga BLOND! Technisch ausgereifte
                    Fähigkeiten wie die Airtouch Methode gehören hier zu meinem Portfolio.
                    <br /> <br />
                    Ich liebe diesen wundervollen Beruf und übe es jeden Tag mit voller Leidenschaft aus.
                </p>

                <p className="pt-6">
                    Buchen Sie jetzt Ihren Termin telefonisch oder per Mail unter:
                </p>

                <div className="pt-2 flex flex-row">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" className="w-5 h-5 mr-2 mt-1">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                    </svg>

                    <a href="tel:+436603143190">
                        +43 660 3143190
                    </a>
                </div>

                <div className="pt-2 flex flex-row">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" className="w-5 h-5 mr-2 mt-1">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                    </svg>

                    <a href="mailto:office@christoph-goll.at" className="underline text-blue-500 hover:text-blue-700">
                        office@christoph-goll.at
                    </a>
                </div>
            </div>


        </div>

        <div className="flex justify-start ml-[50%] w-48 sm:hidden translate-x-[-50%] pb-8">
            <video autoPlay loop muted playsInline>
                <source src={storeVideo} type="video/mp4" />
            </video>
        </div>

        <HorizontalSeparator />
    </>
}