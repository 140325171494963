import { BrowserRouter, Route, Routes } from "react-router-dom"
import PageRoutes from "./routing/PageRoutes"
import NotFoundPage from "./pages/notfound/NotFoundPage"
import Layout from "./components/layout/Layout"

function App() {
    return <BrowserRouter>
        <Layout>
            <Routes>
                {PageRoutes()}
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </Layout>
    </BrowserRouter>
}

export default App
