interface Props {
    image: any
    name: string
    description: string
    link: string
}

const ProductEntry: React.FC<Props> = ({ image, name, description, link }) => {
    return <>
        <div className="sm:flex flex-wrap pl-[20%] min-w-64">
            <div className="block">
                <img src={image} alt="Produktfoto" className="w-36 h-36 rounded-full overflow-hidden object-cover" />
            </div>

            <div className="pl-[7%] pt-3 w-[90%] sm:w-[60%]">
                <p className="font-semibold pb-2">{name}</p>
                <p className="pb-4">{description}</p>
                <a href={link} className="underline text-blue text-blue-500 hover:text-blue-700">{link}</a>
            </div>
        </div>
    </>
}

export default ProductEntry