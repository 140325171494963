import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

type DropDownProps = {
    showDropDown: boolean
    toggleDropDown: Function
}

const DropDown: React.FC<DropDownProps> = () => {
    const [showDropDown, setShowDropDown] = useState<boolean>(false)

    useEffect(() => {
        setShowDropDown(showDropDown)
    }, [showDropDown])

    return <>
        <div id='dropdown'>
            <ul className="bg-black border-2 border-white rounded-md absolute list-none font-semibold text-lg text-left tracking-wide z-50 decoration-2 underline-offset-4 ml-[-22%]">
                <li className="me-4 md:me-10 cursor-pointer hover:underline pb-1">
                    <Link to="/produkte">PRODUKTE</Link>
                </li>
                <li className="me-4 md:me-10 cursor-pointer hover:underline pb-1">
                    <Link to="/preisliste">PREISLISTE</Link>
                </li>
                <li className="me-4 md:me-10 cursor-pointer hover:underline pb-1">
                    <Link to="/ueber">ÜBER MICH</Link>
                </li>
                <li className="me-4 md:me-10 cursor-pointer hover:underline pb-1">
                    <Link to="/">KONTAKT</Link>
                </li>
                <li className="me-4 md:me-10 cursor-pointer hover:underline pb-1">
                    <Link to="/seminare">SEMINARE</Link>
                </li>
            </ul>
        </div>
    </>
}

export default DropDown
