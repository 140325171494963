import HorizontalSeparator from "../../components/layout/HorizontalSeparator"

export default function Imprint() {
    return <div>
        <p className="text-3xl font-semibold tracking-wide text-center pb-5">
            Impressum
        </p>

        <div className="sm:flex sm:justify-between pl-[5%] pr-[5%] pb-5">
            <div className="sm:w-[30%] w-[90%]">
                <div>
                    <p className="font-semibold">FIRMA</p>
                    <p>CHRISTOPH GOLL</p>
                </div>

                <br />

                <div>
                    <p className="font-semibold">ADRESSE</p>
                    <p>Ruflinger Strasse 17</p>
                    <p>4060 Leonding</p>
                </div>

                <br />

                <div>
                    <p className="font-semibold">TELEFON</p>
                    <p>+43 6603143190</p>
                </div>
            </div>

            <div className="sm:w-[30%] w-[90%]">
                <div>
                    <p className="font-semibold">MAIL</p>
                    <a href="mailto:office@christoph-goll.at" className="underline text-blue-500 hover:text-blue-700">
                        office@christoph-goll.at
                    </a>
                </div>

                <br />

                <div>
                    <p className="font-semibold">WEB</p>
                    <a href="http://www.christoph-goll.at" target="_blank" rel="noopener noreferrer"
                        className="underline">
                        www.christoph-goll.at
                    </a>
                </div>
            </div>

            <div className="text-xs sm:w-[30%] w-[90%] sm:pt-0 pt-4">
                <p>
                    Nutzungsrechte <br />
                    Das in der Website enthaltene geistige Eigentum ist geschützt.
                    Durch die Veröffentlichung dieser Website erteilen die Produzenten
                    keine Lizenz zur Nutzung dieses Eigentums an Dritte.Die
                    Produzenten können nicht für den durch den Kunden und dessen
                    Redakteure eingepflegten Inhalte (Texte, Bilder, Links (inklusive
                    Nutzungsrechte) verantwortlich gemacht werden.
                </p>
                <br />
                <p>
                    Haftungsausschuss <br />
                    Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine
                    Haftung für die Inhalte externer Links. Für die Inhalte der verlinkten
                    Seiten sind ausschließlich deren Betreiber verantwortlich.
                </p>
            </div>
        </div>

        <HorizontalSeparator />
    </div>
}