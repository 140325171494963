import CustomCarousel from "../../components/carousel/SaloonCarousel"
import Contact from "../../components/contact/Contact"
import Education from "../../components/education/Education"
import HorizontalSeparator from "../../components/layout/HorizontalSeparator"

export default function Home() {

    return <div>
        <Education />

        <HorizontalSeparator />

        <div className="flex h-96 justify-center pl-[10%] pr-[10%]">
            <CustomCarousel />
        </div>

        <HorizontalSeparator />

        <Contact />

        <HorizontalSeparator />
    </div>
}