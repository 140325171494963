import HorizontalSeparator from "../../components/layout/HorizontalSeparator"
import VerticalSeparator from "../../components/layout/VerticalSeparator"
import SeminarEntry from "../../components/seminar/SeminarEntry"
import christophGollImage from "../../assets/images/christoph_goll_new.jpeg"
import haircutImage from "../../assets/images/headline_images/new_haircut_image.jpg"

export default function Seminar() {
    return <>
        <div className="relative pb-4 cursor-default">
            <div className="flex justify-center">
                <img src={haircutImage} alt="Seminare" className="w-[95%] rounded-sm" />
            </div>

            <div className="absolute bottom-[40%] left-[50%] translate-x-[-50%] z-10">
                <p className="text-3xl font-semibold text-white">SEMINARE</p>
            </div>
        </div>

        <div className="sm:flex justify-between pt-4 sm:pb-24 pb-4">
            <div className="pl-[5%] min-w-64 w-[90%] sm:w-[40%] sm:pb-0 pb-4">
                <SeminarEntry image={christophGollImage} title="Airtouch Seminar" details="Tagesseminar, max. 10 Personen, Modelle müssen selbst organisiert werden"
                    duration="ca. 8 Stunden" description="Erlernen der neuesten Blond Strähnen Technik. Blondeffekte, Balayage, Sunlights, Color Correction."
                    extra="Airtouch Boards werden zur Verfügung gestellt" targetGroup="Für Friseure und Lehrlinge ab dem 3. Lehrjahr"
                    price="€ 1000,- netto / exklusive Reisekosten, eventuell Übernachtungskosten" />
            </div>

            <div className="sm:block hidden">
                <VerticalSeparator />
            </div>

            <div className="sm:pl-[0%] pl-[5%] pr-[5%] min-w-64 w-[90%] sm:w-[40%] sm:pb-0 pb-4">
                <SeminarEntry image={christophGollImage} title="Classic Haircut Damen" details="Buchbar ab 1 - 2 Tage , max. 8 Personen , Übungskopf (Puppenkopf) wird zur Verfügung gestellt"
                    duration="8 Stunden pro Tag (Pro Tag max. 2 Haarschnitte und die dazugehörige Trendfarbe / Technik)"
                    description="Erlerne die neusten Kollektions Haarschnitte und Farben aus dem Hause Z-one Concept (Mailand)" extra=""
                    targetGroup="Für alle Stylisten" price="€ 1000,- netto / exklusive Reisekosten, eventuell Übernachtungskosten, Puppenkopf (Einkaufspreis)" />
            </div>
        </div>

        <div className="sm:flex flex-wrap justify-between sm:pb-16 pb-2">
            <div className="pl-[5%] min-w-64 w-[90%] sm:w-[40%] sm:pb-0 pb-4">
                <SeminarEntry image={christophGollImage} title="Kollektions Haircsut / Haircolor Damen" details="Buchbar ab 1  2 Tage , max. 8 Personen , Übungskopf (Puppenkopf) wird zur Verfügung gestellt"
                    duration="8 Stunden pro Tag (Pro Tag max. 2 Haarschnitte und die dazugehörige Trendfarbe / Technik)"
                    description="Erlerne die neusten Kollektions Haarschnitte und Farben aus dem Hause Z-one Concept (Mailand)" extra=""
                    targetGroup="Für alle Stylisten" price="€ 1000,- netto / exklusive Reisekosten, eventuell Übernachtungskosten, Puppenkopf (Einkaufspreis)" />
            </div>

            <div className="sm:block hidden">
                <VerticalSeparator />
            </div>

            <div className="sm:pl-[0%] pl-[5%] pr-[5%] min-w-64 w-[90%] sm:w-[40%] sm:pb-0 pb-4">
                <SeminarEntry image={christophGollImage} title="Kollektions Haircut Herren" details="Buchbar ab 1  2 Tage , max. 8 Personen , Übungskopf (Puppenkopf) wird zur Verfügung gestellt"
                    duration="8 Stunden pro Tag (Pro Tag max. 3 Haarschnitte)"
                    description="Erlerne die neusten Kollektions Haarschnitte und Farben aus dem Hause Z-one Concept (Mailand)" extra=""
                    targetGroup="Für alle Stylisten" price="€ 1000,- netto / exklusive Reisekosten, eventuell Übernachtungskosten, Puppenkopf (Einkaufspreis)" />
            </div>
        </div>

        <HorizontalSeparator />
    </>
}