import haircutImage from "../../assets/images/headline_images/new_haircut_image.jpg"
import ProductEntry from "../../components/products/ProductEntry"
import christophGollImage from "../../assets/images/christoph_goll_new.jpeg"
import HorizontalSeparator from "../../components/layout/HorizontalSeparator"
import depotIcon from "../../assets/images/products/depot_icon.jpeg"
import milkshakeIcon from "../../assets/images/products/milkshake_icon.png"
import CustomCarousel from "../../components/carousel/ProductsCarousel"

export default function Products() {
    return <>
        <div className="relative pb-4 cursor-default">
            <div className="flex justify-center">
                <img src={haircutImage} alt="Produkte" className="w-[95%] rounded-sm" />
            </div>

            <div className="absolute bottom-[40%] left-[50%] translate-x-[-50%] z-10">
                <p className="text-3xl font-semibold text-white">PRODUKTE</p>
            </div>
        </div>

        <ProductEntry name="Depot" image={depotIcon} description="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                    labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
                    et ea rebum." link="https://www.depotmaletools.com/en/" />

        <div className="pt-6 pb-6">
            <HorizontalSeparator />
        </div>

        <ProductEntry name="milk_shake" image={milkshakeIcon} description="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                    labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
                    et ea rebum." link="https://www.milkshakehair.com" />

        <div className="pt-6 pb-5">
            <HorizontalSeparator />
        </div>

        <div className="flex h-96 justify-center pl-[30%] pr-[30%] ">
            <CustomCarousel />
        </div>

        <div className="pt-5">
            <HorizontalSeparator />
        </div>
    </>
}