import instagramLogo from "../../assets/images/instagram_logo.svg"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"

export default function Footer() {
    return <footer className="bg-black h-14 flex flex-row text-white text-center items-center font-semibold text-sm sm:text-base">
        <div className="basis-1/3">
            <ul className="flex flex-wrap items-center justify-center list-none">
                <li className="me-2 hover:animate-bounce">
                    <a href="https://www.instagram.com/chris.gstyle" rel="noopener noreferrer" target="_blank">
                        <img src={instagramLogo} alt="Instagram Logo" className="h-6" />
                    </a>
                </li>
            </ul>
        </div>

        <div className="basis-1/3 tracking-wide cursor-default">
            © Christoph Goll 2023
        </div>

        <div className="basis-1/3">
            <ul className="flex flex-wrap items-center justify-center list-none tracking-wide decoration-2 underline-offset-4 ">
                <li className="me-2 cursor-pointer hover:underline">
                    <Link to="/impressum">IMPRESSUM</Link>
                </li>

                <li className="me-2 cursor-default hover:no-underline">|</li>

                <li className="me-2 cursor-pointer hover:underline">
                    <Link to="/datenschutz">DATENSCHUTZ</Link>
                </li>
            </ul>
        </div>
    </footer>
}